// src/pages/EssentiaExperience.js
import React, { useEffect } from "react";
import $ from "jquery";
import LogoExperience from "../assets/img/experience/essentia-experience.svg";
import EssentiaExperience from "../assets/img/experience/title-essentia-experience.svg";
import ImagemEssentiaExperience from "../assets/img/experience/ImagemEssentiaExperience.jpg";
import ImagemEssentiaExperience_mobile from "../assets/img/experience/ImagemEssentiaExperience-mobile.jpg";
import AuditoriumEssentia from "../assets/img/experience/title-auditorium-essentia.svg";
import ImagemAuditorium from "../assets/img/experience/ImagemAuditorium.jpg";
import ImagemAuditorium_mobile from "../assets/img/experience/ImagemAuditorium-mobile.jpg";
import HealthbarEssentia from "../assets/img/experience/title-healthbar-essentia.svg";
import ImagemHealthbar from "../assets/img/experience/ImagemHealthbar.jpg";
import ImagemHealthbar_mobile from "../assets/img/experience/ImagemHealthbar-mobile.jpg";
import Imagem01 from "../assets/img/experience/img-our-essence-01.jpg";
import Imagem01_mobile from "../assets/img/experience/img-our-essence-01-mobile.jpg";
import Imagem02 from "../assets/img/experience/img-our-essence-02.jpg";
import Imagem03 from "../assets/img/experience/img-our-essence-03.jpg";
import "./EssentiaExperience.css";

const EssentialExperience = () => {
  // const [videoSources, setVideoSources] = useState({
  //   mp4: "https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/background-essentia-experience.mp4",
  //   webm: "https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/background-essentia-experience.webm",
  // });
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = $(window).scrollTop();
      const scaleFactor = window.innerWidth > 768 ? 0.15 : 0.05;
      $(".anime01").css(
        "transform",
        `translateY(${scrollTop * scaleFactor}px)`
      );
    };

    // const updateVideoSource = () => {
    //   if (window.innerWidth <= 768) {
    //     // Dispositivo móvel
    //     setVideoSources({
    //       mp4: "https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/back-mobile.mp4",
    //       webm: "https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/back-mobile.webm",
    //     });
    //   } else {
    //     // Desktop
    //     setVideoSources({
    //       mp4: "https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/background-essentia-experience.mp4",
    //       webm: "https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/background-essentia-experience.webm",
    //     });
    //   }
    // };

    //updateVideoSource();
    $(window).on("scroll", handleScroll);
    //window.addEventListener("resize", updateVideoSource);
    return () => {
      $(window).off("scroll", handleScroll);
      //window.removeEventListener("resize", updateVideoSource);
    };
  }, []);
  return (
    <div>
      <div className="back01-02 experiencePage">
        <div className="section01">
          <video
            className="bg_video responsive-video desktop"
            autoPlay
            muted
            loop
            width="100%"
          >
            <source
              src="https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/background-essentia-experience.mp4"
              type="video/mp4"
            />
          </video>
          <video
            className="bg_video responsive-video mobile"
            playsInline
            autoPlay
            muted
            loop
            width="100%"
          >
            <source
              src="https://essentialnutrition-upload-files.s3.us-east-1.amazonaws.com/site-essentiagroup/essentia-experience/back-mobile.mp4"
              type="video/mp4"
            />
          </video>
          <div className="blc-container">
            <img src={LogoExperience} alt="Logo Essentia Group" />
            <h1>
              Um espaço de <span>conexão</span> com você.
            </h1>
            <span className="subtitle">SÃO PAULO, SP</span>
          </div>
        </div>

        <div className="section02">
          <picture className="anime01">
            <source
              media="(max-width: 767px)"
              srcSet={Imagem01_mobile}
              type="image/jpeg"
            />
            <source
              media="(min-width: 768px)"
              srcSet={Imagem01}
              type="image/jpeg"
            />
            <img src={Imagem01} alt="Imagem 01" />
          </picture>
          <img className="anime02 desktop" src={Imagem02} alt="Imagem 02" />
          <img className="anime03 desktop" src={Imagem03} alt="Imagem 03" />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>
                  LET <em>OUR ESSENCE</em> SURROUND YOU.
                </h2>
                <h3>
                  Queremos contar o que <em>nos apaixona,</em>{" "}
                  <br className="desktop" />
                  compartilhar o que <em>nos inspira</em>{" "}
                  <br className="desktop" />e incentivar você a{" "}
                  <em>aproveitar</em> <br className="desktop" />o que a vida{" "}
                  <strong>tem de melhor.</strong>
                </h3>
                <p className="txt01">
                  O <strong>Essentia Experience</strong> é um projeto pensado
                  pelo <strong>Essentia Group</strong> para compartilhar
                  conhecimento, ampliar a visão sobre saúde e vivenciar{" "}
                  <strong>tudo aquilo que faz parte da nossa essência.</strong>
                </p>
                <p className="txt02">
                  Conheça um espaço único em São Paulo, onde reunimos{" "}
                  <strong>todas as nossas marcas</strong> para transmitir como
                  servimos de diferentes formas ao{" "}
                  <strong>desenvolvimento</strong> do ser humano.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section03">
        <div className="letterAnim letter01">
          <div className="animTextBack"></div>
        </div>
        <div className="container">
          <div className="row row01">
            <div className="col-12 col-sm-5 col-sm-offset-1">
              <img src={EssentiaExperience} alt="Essentia Experience" />
              <p className="txt01">
                Cada uma das nossas marcas tem um espaço dedicado e pensado para
                transmitir a nossa essência por meio de uma experiência
                imersiva. <br />
                <br />
                Conheça as diferentes formas que encontramos de servir ao
                desenvolvimento do ser humano e experiencie o que há de melhor e
                mais inovador para despertar todo o seu potencial.
              </p>
            </div>
            <div className="col-12 col-sm-6">
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={ImagemEssentiaExperience_mobile}
                  type="image/jpeg"
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={ImagemEssentiaExperience}
                  type="image/jpeg"
                />
                <img
                  className="anime04"
                  src={ImagemEssentiaExperience}
                  alt="Imagem Essentia Experience"
                />
              </picture>
            </div>
          </div>
          <div className="row row02">
            <div className="col-12 col-sm-6">
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={ImagemHealthbar_mobile}
                  type="image/jpeg"
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={ImagemHealthbar}
                  type="image/jpeg"
                />
                <img
                  className="anime06"
                  src={ImagemHealthbar}
                  alt="Imagem Healthbar Essentia"
                />
              </picture>
            </div>
            <div className="col-12 col-sm-5 col-sm-offset-1">
              <p className="txt03">
                Desfrute de receitas saudáveis, gostosas e nutritivas preparadas
                com os produtos da <strong>Essential Nutrition.</strong> <br />
                <br />
                Perceba como a alimentação é uma aliada do bem-estar e como pode
                ser fácil integrá-la ao seu dia a dia.
              </p>
            </div>
          </div>
          <div className="row row03">
            <div className="col-12 col-sm-5 col-sm-offset-1">
              <img src={AuditoriumEssentia} alt="Auditorium Essentia" />
              <p className="txt02">
                Um espaço destinado para ampliar e desfrutar da troca de
                conhecimento através de palestras, aulas, cursos e treinamentos
                para servir o seu bem-estar.
              </p>
            </div>
            <div className="col-12 col-sm-6">
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={ImagemAuditorium_mobile}
                  type="image/jpeg"
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={ImagemAuditorium}
                  type="image/jpeg"
                />
                <img
                  className="anime05"
                  src={ImagemAuditorium}
                  alt="Imagem Auditorium Essentia"
                />
              </picture>
            </div>
          </div>
        </div>
        <div className="letterAnim letter02">
          <div className="animTextBack"></div>
        </div>
      </div>
      <div className="section04">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                Desfrute dessa <em>experiência</em> e desperte para o{" "}
                <em>seu potencial</em>.
              </h2>
              <p>
                Rua João Lourenço, 530 <br />
                Vila Nova Conceição - São Paulo
              </p>
              <span className="divisor"></span>
              <p>
                essentiaexperience@essentiagroup.com.br <br />
                (11) 98850-1800
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EssentialExperience;
